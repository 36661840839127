<template>
  <NuxtLayout :name="layout">
      <NuxtPage></NuxtPage>
  </NuxtLayout>
</template>

<script setup>
// You might choose this based on an API call or logged-in status
const layout = "default";
const description = 'Dr. Wordpress hilf dir, eine moderne und leicht bedienbare Website zu erstellen und zu betreiben. Egal ob die Erstellung, individuelle Anpassungen, Reparierung oder Wartung.'
useSeoMeta({
  title: 'Dr. Wordpress',
  description: description,
  ogDescription: description  
})
useHead({
    htmlAttrs: { lang: 'de' },
    link: [{ rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' }]  
  });
</script>
