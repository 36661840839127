import { default as index5Ue3qUZZvuMeta } from "/home/carlosdata/nuxtwp/nuxtwp/pages/index.vue?macro=true";
import { default as _91slug_93meIAgW1CI8Meta } from "/home/carlosdata/nuxtwp/nuxtwp/pages/post/[slug].vue?macro=true";
export default [
  {
    name: index5Ue3qUZZvuMeta?.name ?? "index",
    path: index5Ue3qUZZvuMeta?.path ?? "/",
    meta: index5Ue3qUZZvuMeta || {},
    alias: index5Ue3qUZZvuMeta?.alias || [],
    redirect: index5Ue3qUZZvuMeta?.redirect || undefined,
    component: () => import("/home/carlosdata/nuxtwp/nuxtwp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93meIAgW1CI8Meta?.name ?? "post-slug",
    path: _91slug_93meIAgW1CI8Meta?.path ?? "/post/:slug()",
    meta: _91slug_93meIAgW1CI8Meta || {},
    alias: _91slug_93meIAgW1CI8Meta?.alias || [],
    redirect: _91slug_93meIAgW1CI8Meta?.redirect || undefined,
    component: () => import("/home/carlosdata/nuxtwp/nuxtwp/pages/post/[slug].vue").then(m => m.default || m)
  }
]